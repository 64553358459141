import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { i18n } from '../../i18n/index';
import PageTitleAsBreadCrumbs from '../sharedComponents/PageTitleAsBreadCrumbs';
import LastUpdateTime from '../sharedComponents/LastUpdateTime';
import RecipeIngredientGroupsTabs from './RecipeIngredientGroupsTabs';
import AddNewIngredientPopup from './AddNewIngredientPopup';
import { recipeSaveService } from '../../services/recipeSaveService';
import { productsStorage } from '../../services/productsStorage';
import { recipesService } from '../../services/recipesService';
import ItemName from '../sharedComponents/ItemName';
import SelectWithoutTokens from '../sharedComponents/SelectWithoutTokens';
import DeleteIngredientPopup from './DeleteIngredientPopup';
import {
    RECIPE_CHARACTERISTIC_TASTE_BITTER,
    RECIPE_CHARACTERISTIC_TASTE_SALTY, RECIPE_CHARACTERISTIC_TASTE_SOUR, RECIPE_CHARACTERISTIC_TASTE_SPICY,
    RECIPE_CHARACTERISTIC_TASTE_SWEET, RECIPE_CHARACTERISTIC_TASTE_UMAMI, RECIPE_TYPES,
} from "../../constant";
import {RecipeTasteSweetIcon} from "../../appearence/icons/recipe_taste_sweet_icon";
import HintItem from "../sharedComponents/HintItem";
import {RecipeTasteBitterIcon} from "../../appearence/icons/recipe_taste_bitter_icon";
import {RecipeTasteSaltyIcon} from "../../appearence/icons/recipe_taste_salty_icon";
import {RecipeTasteSourIcon} from "../../appearence/icons/recipe_taste_sour_icon";
import {RecipeTasteSpicyIcon} from "../../appearence/icons/recipe_taste_spicy_icon";
import {RecipeTasteUmamiIcon} from "../../appearence/icons/recipe_taste_umami_icon";

const createFullList = () => {
    return RECIPE_TYPES.map(it => {
        return {id: it, code: it, name: i18n.get(`recipe.item.type.${it}`)};
    });
};

const RecipeTasteSweetCharacteristic = ({characteristic}) => {
    const [isHovered, setIsHovered] = useState(false);
    return (
        <span className="item__hint-wrapper" key={characteristic.code}>
            <span
                onMouseOver={() => setIsHovered(true)}
                onMouseOut={() => setIsHovered(false)}
                onClick={() => recipeSaveService.updateCharacteristic(characteristic.value)}>
                <RecipeTasteSweetIcon nameOfClass={`product__list-icon ${characteristic.isActive ? '' : 'product__list-icon_disabled'}`}/>
            </span>
            {isHovered ? <HintItem message={i18n.get(`recipe.scheme.characteristic.${characteristic.category}.${characteristic.value}`)} /> : null}
        </span>
    );
};

const RecipeTasteBitterCharacteristic = ({characteristic}) => {
    const [isHovered, setIsHovered] = useState(false);
    return (
        <span className="item__hint-wrapper" key={characteristic.code}>
            <span
                onMouseOver={() => setIsHovered(true)}
                onMouseOut={() => setIsHovered(false)}
                onClick={() => recipeSaveService.updateCharacteristic(characteristic.value)}>
                <RecipeTasteBitterIcon nameOfClass={`product__list-icon ${characteristic.isActive ? '' : 'product__list-icon_disabled'}`}/>
            </span>
            {isHovered ? <HintItem message={i18n.get(`recipe.scheme.characteristic.${characteristic.category}.${characteristic.value}`)} /> : null}
        </span>
    );
};

const RecipeTasteSaltyCharacteristic = ({characteristic}) => {
    const [isHovered, setIsHovered] = useState(false);
    return (
        <span className="item__hint-wrapper" key={characteristic.code}>
            <span
                onMouseOver={() => setIsHovered(true)}
                onMouseOut={() => setIsHovered(false)}
                onClick={() => recipeSaveService.updateCharacteristic(characteristic.value)}>
                <RecipeTasteSaltyIcon nameOfClass={`product__list-icon ${characteristic.isActive ? '' : 'product__list-icon_disabled'}`}/>
            </span>
            {isHovered ? <HintItem message={i18n.get(`recipe.scheme.characteristic.${characteristic.category}.${characteristic.value}`)} /> : null}
        </span>
    );
};

const RecipeTasteSourCharacteristic = ({characteristic}) => {
    const [isHovered, setIsHovered] = useState(false);
    return (
        <span className="item__hint-wrapper" key={characteristic.code}>
            <span
                onMouseOver={() => setIsHovered(true)}
                onMouseOut={() => setIsHovered(false)}
                onClick={() => recipeSaveService.updateCharacteristic(characteristic.value)}>
                <RecipeTasteSourIcon nameOfClass={`product__list-icon ${characteristic.isActive ? '' : 'product__list-icon_disabled'}`}/>
            </span>
            {isHovered ? <HintItem message={i18n.get(`recipe.scheme.characteristic.${characteristic.category}.${characteristic.value}`)} /> : null}
        </span>
    );
};

const RecipeTasteSpicyCharacteristic = ({characteristic}) => {
    const [isHovered, setIsHovered] = useState(false);
    return (
        <span className="item__hint-wrapper" key={characteristic.code}>
            <span
                onMouseOver={() => setIsHovered(true)}
                onMouseOut={() => setIsHovered(false)}
                onClick={() => recipeSaveService.updateCharacteristic(characteristic.value)}>
                <RecipeTasteSpicyIcon nameOfClass={`product__list-icon ${characteristic.isActive ? '' : 'product__list-icon_disabled'}`}/>
            </span>
            {isHovered ? <HintItem message={i18n.get(`recipe.scheme.characteristic.${characteristic.category}.${characteristic.value}`)} /> : null}
        </span>
    );
};

const RecipeTasteUmamiCharacteristic = ({characteristic}) => {
    const [isHovered, setIsHovered] = useState(false);
    return (
        <span className="item__hint-wrapper" key={characteristic.code}>
            <span
                onMouseOver={() => setIsHovered(true)}
                onMouseOut={() => setIsHovered(false)}
                onClick={() => recipeSaveService.updateCharacteristic(characteristic.value)}>
                <RecipeTasteUmamiIcon nameOfClass={`product__list-icon ${characteristic.isActive ? '' : 'product__list-icon_disabled'}`}/>
            </span>
            {isHovered ? <HintItem message={i18n.get(`recipe.scheme.characteristic.${characteristic.category}.${characteristic.value}`)} /> : null}
        </span>
    );
};

const recipeCharacteristicIcons = new Map([
    [RECIPE_CHARACTERISTIC_TASTE_SWEET, (characteristic) => <RecipeTasteSweetCharacteristic characteristic={characteristic} />],
    [RECIPE_CHARACTERISTIC_TASTE_BITTER, (characteristic) => <RecipeTasteBitterCharacteristic characteristic={characteristic} />],
    [RECIPE_CHARACTERISTIC_TASTE_SALTY, (characteristic) => <RecipeTasteSaltyCharacteristic characteristic={characteristic} />],
    [RECIPE_CHARACTERISTIC_TASTE_SOUR, (characteristic) => <RecipeTasteSourCharacteristic characteristic={characteristic} />],
    [RECIPE_CHARACTERISTIC_TASTE_SPICY, (characteristic) => <RecipeTasteSpicyCharacteristic characteristic={characteristic} />],
    [RECIPE_CHARACTERISTIC_TASTE_UMAMI, (characteristic) => <RecipeTasteUmamiCharacteristic characteristic={characteristic} />],
]);

const getCharacteristicIcon = (characteristic) => {
    if (!recipeCharacteristicIcons.get(characteristic.value)) {
        return null;
    }
    return recipeCharacteristicIcons.get(characteristic.value)(characteristic);
};

const RecipeEditTab = () => {
    const [recipeIsChanged, setRecipeIsChanged] = useState(null);
    const { id } = useParams();    
    
    const changeRecipe = () => {
        setRecipeIsChanged(prev => !prev);
    };

    useEffect(() => {
        recipeSaveService.loadRecipe(id, () => {});
        productsStorage.loadItems(() => changeRecipe());
    }, [id]);

    useEffect(() => {
        recipeSaveService.registerItemUpdateObserver(changeRecipe);
        return () => {
            recipeSaveService.unRegisterItemUpdateObserver(changeRecipe);
            recipeSaveService.cleanService();
        };
    }, []);

    const changeRecipeCode = (recipe) => {
        recipeSaveService.updateRecipeType(recipe.code);
    };

    return (
        <section className="workspace workspace__recipe">
            <AddNewIngredientPopup />
            <DeleteIngredientPopup />
            <PageTitleAsBreadCrumbs />
            {recipeIsChanged !== null ?
                <article className="recipe__article recipe__article-add">
                    <LastUpdateTime itemSaveService={recipeSaveService} />
                    <form className="recipe__form" key={recipeIsChanged + 'recipeForm'}>
                        <ItemName itemCode={recipeSaveService.getRecipe().recipeType} itemService={recipesService} itemSaveService={recipeSaveService}/>
                        <label className="block__text recipe__form-label recipe__form_lowercase recipe__form-modal">
                            {i18n.get('app.page.recipe.recipeType')}
                            <SelectWithoutTokens listOfOptions={createFullList()} handleChangeValue={changeRecipeCode} 
                                titleValue={recipesService.getLocalization(i18n.APP_LOCALE, recipeSaveService.getRecipe().recipeType, true)} />
                        </label>
                        <label className="block__text recipe__form-label recipe__form_lowercase recipe__form-modal">
                            {i18n.get('app.page.recipe.recipeCharacteristic.taste')}
                            <label>{recipeSaveService.getTasteCharacteristics().map(it => getCharacteristicIcon(it))}</label>
                        </label>
                    </form>
                    <RecipeIngredientGroupsTabs />
                </article>
                :
                null}
        </section>
    );
};

export default RecipeEditTab;
